import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import App from './App';
import './index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { gtmCl } from './config/config';

TagManager.initialize({ gtmId: gtmCl });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
